.m-footer-container{
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  background-color:#f1f2f6;
  padding: 2rem;
}

.m-tel-content{
  width: 100%;
  display: flex;
  text-align: left;
  margin-left: 4rem;
  margin-bottom: .4rem;
  color:#a1a4a7;
}
.m-tel-content:last-child{
  margin-bottom: 0;
}
.m-title{
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  .m-icon{
    font-size: 1.2rem;
    margin-right: .8rem;
  }
}
@primary-color: #FA1011;