.page-header {
  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 80px;
    background-color: transparent;
    visibility: hidden;
  }
}
.header-container {
  height: 80px;
  background-color: #fff;
  transition: all .3s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  display: flex;
  justify-content: center;

  .header-mid {
    width: 1200px;
    min-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-name {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    
    .logo {
      width: 80px;
      height: 80px;
      display: block;
      cursor: pointer;
    }

    .name {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      cursor: pointer;
      .cn-name {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        text-align: left;
      }
      .en-name {
        font-size: 12px;
        font-weight: 400;
        color: #999;
        text-align: left;
      }
    }
  }
  

  .header-nav {
    width: 50%;
    height: 100%;

    .ant-menu {
      height: 100%;
      background-color: transparent;
      border: none;
      justify-content: flex-end;
    }
    .ant-menu-horizontal {
      line-height: 80px;
    }
  }
}
@primary-color: #FA1011;