.App {
  width: 100%;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .page-main {
    flex: 1;
  }
  .container {
    width: 100%;
    overflow-x: hidden;
  }
}
:root:root {
  --adm-color-primary: #FA1011;
}


@primary-color: #FA1011;