.contract-cotainer{
  background-color: #f6f8fb;
  padding:2rem ;
  height: calc(100vh - 30rem);
  min-height: 40rem;
  // display: flex;
  // align-items: center;
  .Card-content{
    flex: 1;
    height: 36rem;
    margin-top: 2rem;
    .msg-container{
      display: flex;
      position: relative;
      height: 36rem;
      // padding: 1.4rem;
      .m-content-left{
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;
        margin-left: 2rem;
        margin-top: 2.4rem;
        .m-phone-content{
          display: flex;
          &:last-child{
            margin-bottom: 0;
          }
          .msg-title{
            margin-right: .8rem;
          }
          a{
            color: rgba(0, 0, 0, 0.85);
          }
          .list-email{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            a{
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }
      }
      .qr-code{
        width: 14rem;
        height: 14rem;
        position: absolute;
        bottom: 4rem;
        right: 3.2rem;
  
        img{
          width: 100%;
        }
        div{
          margin-top: 0.4rem;
          color: #c6bfbf;
          font-size: 1.2rem;
          white-space: nowrap;
        }
      }
    }

  }
  
}
@primary-color: #FA1011;