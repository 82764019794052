.about-page {
  width: 100%;
}

.about-conent {
  width: 100%;
  // height: 800px;
  // display: flex;
  max-width: 1200px;
  // flex-direction: row-reverse;
  padding-top: 20px;
  box-sizing: border-box;
  margin: auto;
  // position: relative;
  .about-intro {
    width: 100%;
    .about-intro-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
      .about-img-bg {
        width: 100%;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.3);
        }

        img {
          width: 100%;
          // height: 100%;
          // opacity: .8;
        }
      }
      .description {
        width: 600px;
        position: absolute;
        top: 74%;
        left: 20px;
        transform: translateY(-50%);
        padding: 20px;
        box-sizing: border-box;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        .paragraph {
          text-align: left;
          margin-bottom: 12px;
          text-indent: 2em;
        }
      }
    }
    .about-steps-wrapper {
      height: 400px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .about-steps-title {
        color: #6e6d6d;
        font-size: 20px;
        margin-bottom: 30px;
      }
      .about-steps {
        margin: 50px 0;
      }
    }
  }
}
.about-makets {
  width: 1200px;
  margin: 10px auto 0;
  .about-makets-title {
    font-size: 28px;
    width: 100%;
    text-align: left;
    color: #333;
    font-weight: 300;
  }
  .maket-map {
    height: 500px;
  }
  .maket-map,
  .maket-factories {
    display: flex;
    flex-direction: column;
    // width: 1200px;
    .maket-map-title,
    .maket-factories-title {
      color: #6e6d6d;
      font-size: 20px;
      margin-bottom: 50px;
    }
    .maket-map-wrapper,
    .maket-factories-wrapper {
      display: flex;
      justify-content: space-between;
      .img {
        width: 48%;
        object-fit: cover;
      }
    }
    .maket-factories-wrapper {
      flex-wrap: wrap;
      row-gap: 30px;
    }
  }
}
.about-supports {
  width: 1200px;
  margin: auto;
  .about-supports-title {
    font-size: 28px;
    width: 100%;
    text-align: left;
    color: #333;
    font-weight: 300;
    margin: 50px 0;
  }
  .about-supports-wrapper {
    display: flex;
    justify-content: space-around;
    height: 700px;
    .supports-img-wrapper {
      width: 40%;
      height: 620px;
      position: relative;
      .supports-img {
        position: absolute;
        width: 240px;
        padding: 10px;
        box-sizing: border-box;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .supports-img1 {
        left: 200px;
        top: 0;
      }
      .supports-img2 {
        top: 130px;
        left: 100px;
      }
      .supports-img3 {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      .supports-img4 {
        bottom: 115px;
        left: 100px;
      }
      .supports-img5 {
        bottom: -10px;
        left: 200px;
      }
    }
    .support-right {
      width: 60%;
      height: 620px;
      line-height: 620px;
      img {
        width: 600px;
        object-fit: cover;
      }
    }
  }
}

@primary-color: #FA1011;