.logo-content{
  width: 80px;
  height: 80px;
  img{
    width: 100%;
    height: 100%;
  }
}
.content{
  width: 1200px;
  height: 380px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto;
  .content-left{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    .phone-content{
      display: flex;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      .msg-title{
        margin-right: 8px;
      }
      a{
        color: rgba(0, 0, 0, 0.85);
      }
      .list-email{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a{
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
  .qr-code{
    width: 160px;
    height: 160px;
    img{
      width: 100%;
      height: 100%;
    }
    div{
      margin-top: 4px;
      color: #c6bfbf;
      font-size: 12px;
      text-align: center;
      white-space: nowrap;
    }
  }
  
}

@primary-color: #FA1011;