.img-wrapper,
.wovenseries-wrapper {
  width: 1200px;
  min-height: calc(100vh - 168px);
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;
  .product-item {
    // width: 380px;
    height: 520px;
    position: relative;
    cursor: pointer;
    transform: all 0.3s linear;
    img {
      // width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 6px 5px 4px -1px rgba(0, 0, 0, 0.28);
    }
    // .cutKnit-img02{
    //   position: absolute;
    //   height: 665px;
    //   left: 0;
    // }
    // .cutKnit-img04{
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    // }
    // div{
    //   color: #fff;
    //   font-size: 18px;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   height: 100%;
    //   width: 100%;
    //   z-index: 10;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
  }
  .cuknit-center {
    flex: 1;
    flex-wrap: wrap;
    height: 520px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 10px;
    .product-item1 {
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .product-itembg {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
.wovenseries-wrapper {
  flex-wrap: wrap;
  row-gap: 50px;
  .product-wovenseries {
    img {
      width: 100%;
      object-fit: cover;
      box-shadow: 6px 5px 4px -1px rgba(0, 0, 0, 0.28);
    }
  }
}
.img-box {
  width: 1200px;
  height: calc(100vh - 168px);
  display: flex;
  align-items: center;
  margin: auto;
  .outerwear-wrapper {
    display: flex;
    width: 100%;
    box-shadow: 6px 5px 4px -1px rgba(0, 0, 0, 0.28);
    .product-item {
      width: 580px;
      position: relative;
      cursor: pointer;
      transform: all 0.3s linear;
      img {
        // width: 100%;
        height: 100%;
        object-fit: cover;
        // box-shadow: 6px 5px 4px -1px rgba(0,0,0,0.28);
      }
    }
  }
}
.accessories {
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  box-shadow: 6px 5px 4px -1px rgba(0, 0, 0, 0.28);
  .product-accessories {
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@primary-color: #FA1011;