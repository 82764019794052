.page-footer {
  width: 100%;
  height: 88px;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-mid {
    width: 1200px;
    height: 100%;
    color: #fff;
    font-weight: 300;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .footer-row {
      width: 100%;
      display: flex;
      justify-content: center;

      .tel-fax {
        .tel, .fax {
          display: flex;

          .tel-content, .fax-content {
            margin-left: 10px;
          }
        }
      }

      .email {
        display: flex;
        margin-left: 40px;
        .email-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 10px;
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
@primary-color: #FA1011;