.cutknit-wrapper {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  .cutknit-box {
    width: 100%;
    margin-bottom: 2rem;
    img {
      width: 100%;
      box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.28);
    }
  }
}

@primary-color: #FA1011;