.m-about-conatiner{
  width: 100%;
  .m-img{
    position: relative;
    width: 100%;
    &::after{
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.2);
    }
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  .m-content{
    margin: 2rem 1rem;
    background-color:rgba(0,0,0,.34);
    padding: 1rem;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: left;
    box-shadow: 1px 0 2px rgba(0,0,0,.34);
    h1{
      height: 3.6rem;
      color: #fff;
      margin-bottom: 1.4rem;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      text-indent: 0;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 3.6rem;
        height: 0.52rem;
        background-color: #fa1011;
      }
    }
    .m-paragraph{
      text-indent: 2em;
    }
    // .support-wrapper{
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // height: 80rem;
      .support-box{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 1rem;
        // justify-content: center;
        flex-wrap: wrap;
        .supprt-img-wrapper{
          width: 100%;
          // height: 8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border-top-left-radius: 1.2rem;
          border-bottom-right-radius: 1.2rem;
          img{
            width: 100%;
            object-fit: cover;
          }
        }
      }
    // }
  }
  // .makets-img{
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding-top: 1rem;
  //   div{
  //     width: 48%;
  //     img{
  //       width: 100%;
  //       object-fit: cover;
  //     }
  //   }
  // }
  .makets-title{
    margin: 2rem 0;
  }
  .about-img-box{
    width: 100%;
    img{
      width: 100%;
      object-fit: cover;
    }
  }
}
.adm-step-description{
  color: #eee!important;
}
.company-line{
  margin: 1rem  0 2rem;
}
@primary-color: #FA1011;