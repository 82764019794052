.carousel-item{
  width: 100%;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .carousel-img{
    width: 100%;
    object-fit: cover;
  }
  .carousel-text {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    .carousel-text-title {
      width: 100%;
      text-align: right;
      padding-right: 2rem;
      box-sizing: border-box;
      font-size: 2.2rem;
      margin-bottom: 0;
    }
    .carousel-text-p1,
    .carousel-text-p2,
    .carousel-text-p3,
    .carousel-text-p4 {
      width: 100%;
      text-align: right;
      font-size: 1.6rem;
      box-sizing: border-box;
      padding-right: 2rem;
      margin-bottom: 0;
    }
  }
}
.group-info{
  margin-top: 2rem;
  position: relative;
  height: 32rem;
  .group-title {
    font-size: 1.8rem;
    width: 100%;
    text-align: left;
    color: #333;
    font-weight: 300;
  }
  .group-box{
    height: 90%;
    margin-top: 1rem;
    overflow-y: scroll;
    .group-img {
      display: block;
      width: 50%;
      float: left;
      padding: 2rem;
      box-sizing: border-box;
      // margin-right: 1rem;
      // position: absolute;
      // left: 0;
      // top: 4.8rem;
      // z-index: 1;
    }
  
    .group-info-content {
      width: 100%;
      text-indent: 2em;
      // position: absolute;
      // right: 0;
      // top: 20rem;
      // z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 2rem;
      box-sizing: border-box;
      .group-info-content-p {
        width: 100%;
        text-align: justify;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.5;
      }
      .margin-bottom-0 {
        margin-bottom: 0;
      }
    }
  }

  
}

.our-products {
  width: 100%;
  margin-top: 2rem;
  position: relative;
  .our-products-title {
    font-size: 1.8rem;
    font-weight: 300;
    width: 100%;
    text-align: left;
    color: #333;
  }

  .products-content {
    width: 100%;
    margin-top: 1rem;
    // height: 1200px;
    // display: grid;
    display: flex;
    justify-content: space-between;
    row-gap: 1.4rem;
    flex-wrap: wrap;
    // grid-template-columns: repeat(2, 590px);
    // justify-content: space-between;
    // align-content: space-between;

    .products-content-item {
      width: 48%;
      // height: 590px;
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      .product-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
      .product-info {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        font-size: 26px;
        font-weight: 300;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.our-partner {
  width: 100%;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .our-partner-title {
    font-size: 1.8rem;
    font-weight: 300;
    width: 100%;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before, &::after {
      content: '';
      display: block;
      flex: 1;
      height: 0;
      border-bottom: 1px solid #999;
      transform: scaleY(0.5);
    }
    &::before {
      margin-right: 2rem;
    }
    &::after {
      margin-left: 2rem;
    }
  }

  .partner-content {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    justify-content: space-around;
    justify-items: center;
    row-gap: 1.8rem;

    .partner-item {
      width: 10rem;
      height: 10rem;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }
    .adidas {
      background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/adidas1.png');
      &:hover {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/adidas2.png');
      }
    }
    .columbia {
      background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/columbia1.png');
      &:hover {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/columbia2.png');
      }
    }
    .fila {
      background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/fila1.png');
      &:hover {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/fila2.png');
      }
    }
    .nike {
      background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/nike1.png');
      &:hover {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/nike2.png');
      }
    }
    .puma {
      background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/puma1.png');
      &:hover {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/puma2.png');
      }
    }
  }
}
@primary-color: #FA1011;