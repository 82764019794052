.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-sizing: border-box;
  .ant-carousel {
    width: 1200px;
    height: 600px;

    .carousel-item {
      width: 1200px;
      height: 600px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      .carousel-img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }

      .carousel-text {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-weight: 500;

        .carousel-text-title {
          width: 100%;
          text-align: right;
          padding-right: 60px;
          box-sizing: border-box;
          font-size: 32px;
        }
        .carousel-text-p1,
        .carousel-text-p2,
        .carousel-text-p3,
        .carousel-text-p4 {
          width: 100%;
          text-align: right;
          font-size: 20px;
          box-sizing: border-box;
          padding-right: 60px;
        }
      }
    }
  }

  .group-info {
    width: 1200px;
    height: 597px;
    margin-top: 20px;
    position: relative;

    .group-title {
      font-size: 28px;
      width: 100%;
      text-align: left;
      color: #333;
      font-weight: 300;
    }

    .group-img {
      display: block;
      width: 660px;
      height: 440px;
      position: absolute;
      left: 0;
      top: 60px;
      z-index: 1;
    }

    .group-info-content {
      width: 900px;
      position: absolute;
      right: 0;
      top: 200px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 20px;
      box-sizing: border-box;
      .group-info-content-p {
        width: 100%;
        text-align: justify;
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
      }
      .margin-bottom-0 {
        margin-bottom: 0;
      }
    }
  }

  .our-products {
    width: 1200px;
    margin-top: 20px;
    position: relative;
    .our-products-title {
      font-size: 28px;
      font-weight: 300;
      width: 100%;
      text-align: left;
      color: #333;
    }

    .products-content {
      width: 100%;
      margin-top: 10px;
      height: 1200px;
      display: grid;
      grid-template-columns: repeat(2, 590px);
      justify-content: space-between;
      align-content: space-between;

      .products-content-item {
        width: 590px;
        height: 590px;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }

        .product-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
        .product-info {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          font-size: 26px;
          font-weight: 300;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .our-partner {
    width: 1200px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .our-partner-title {
      font-size: 28px;
      font-weight: 300;
      width: 100%;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before, &::after {
        content: '';
        display: block;
        flex: 1;
        height: 0;
        border-bottom: 1px solid #999;
        transform: scaleY(0.5);
      }
      &::before {
        margin-right: 20px;
      }
      &::after {
        margin-left: 20px;
      }
    }

    .partner-content {
      width: 900px;
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(3, 280px);
      justify-content: space-between;
      row-gap: 20px;

      .partner-item {
        width: 280px;
        height: 280px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
      }
      .adidas {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/adidas1.png');
        &:hover {
          background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/adidas2.png');
        }
      }
      .columbia {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/columbia1.png');
        &:hover {
          background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/columbia2.png');
        }
      }
      .fila {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/fila1.png');
        &:hover {
          background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/fila2.png');
        }
      }
      .nike {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/nike1.png');
        &:hover {
          background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/nike2.png');
        }
      }
      .puma {
        background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/puma1.png');
        &:hover {
          background-image: url('https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/puma2.png');
        }
      }
    }
  }
}
@primary-color: #FA1011;