.m-header-container{
  position: relative;
  height: 6rem;
  .m-header-content{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 0 1.6rem;
    background: #fff;
    z-index: 100;
    box-sizing: border-box;
  }
}
.m-logo-content{
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img{
    width: 5rem;
    height: 5rem;
  }
  .name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    .cn-name{
      color: #333;
      font-weight: 500;
    }
    .en-name{
      font-weight: 400;
      color: #999;
      scale: .9;
      margin-left: -1rem;
      white-space: nowrap;
    }
  }
}
.popup-body{
  width: 80%;
}
.panel-close{
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.8rem;
  line-height: 4.8rem;
  background-color: #333;
  padding-left: 1.6rem;
  .close-icon{
    color: #fff;
    font-size: 2rem;
  }
}
.collapse{
  color: #333;
  .adm-list-item-content{
    font-size: 1.4rem;
  }
  a:hover{
    color: #333;
  }
}
.panel-item{
  width: 100%;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  .panel-arrow{
    font-size: 1.6rem;
    transition: all .3s ease;
  }
  .panel-arrow-down{
    transform: rotate(0);
  }
  .panel-arrow-up{
    transform: rotate(180deg);
  }
 
}
.panel-menu-container{
  overflow: hidden;
  transition: all .4s ease;
  .panel-menu-item{
    width: 100%;
    height: 4.6rem;
    line-height: 4.6rem;
    padding-left: 2.4rem;
    border-bottom: 1px solid #eee;
  }
}
.active-color{
  color: #FA1011;
}
@primary-color: #FA1011;